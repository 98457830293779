import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ClickableLink extends Component {

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(e) {
    e.preventDefault()
    this.props.handleClick()
  }

  // NOTE: This generates a warning: https://github.com/evcohen/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md
  // While that all is very compelling, their CSS didn't prevent the font from
  // being tiny (and it appears not to be due to styles applied to a, but rather
  // how buttons apply "em" font styles to their contents)
  render() {
    let { text, className } = this.props
    return (
      <a className={className} href="#click" onClick={this.handleClick}>
        {text}
      </a>
    )
  }
}

ClickableLink.propTypes = {
  text: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  className: PropTypes.string
}

export default ClickableLink
